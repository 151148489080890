

export const getEnvVariables = () => {

    // import.meta.env
    // console.log({...process.env})  ;

    return {
        ...process.env,
        // REACT_APP_URL_API: process.env.REACT_APP_URL_API,
    }


}