import { useDispatch, useSelector } from "react-redux"
import  loginApi  from "../api/loginApi";

import { clearErrorMessage, onChecking, onLogin, onLogout } from "../slice/authSlice";


export const useAuthStore = () => {


    const { status, user, errorMessage } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const startLogin = async ({ email, password }) => {
        console.log({email, password})
        dispatch(onChecking());

        try {
            const { data } = await loginApi.post('/auth', { email, password });
            console.log({data});
            localStorage.setItem('token', data.token);
            localStorage.setItem('token-init-date', new Date().getTime());
            dispatch(onLogin({ name: data.name, uid: data.uid }));

        } catch (error) {
            console.log({ error })
            dispatch( onLogout( 'Login incorrecto'));
            setTimeout(() => {
                dispatch( clearErrorMessage() );
            }, 10);
        }

    }


    const startRegister = async ({ name, email, password }) => {
        console.log({name, email, password})
        dispatch(onChecking());

        try {
            const { data } = await loginApi.post('/auth/new', { name, email, password });
            console.log({data});
            localStorage.setItem('token', data.token);
            localStorage.setItem('token-init-date', new Date().getTime());
            dispatch(onLogin({ name: data.name, uid: data.uid }));

        } catch (error) {
            console.log({ error })
            dispatch( onLogout( error.response.data?.msg || '--' ));
            setTimeout(() => {
                dispatch( clearErrorMessage() );
            }, 10);
        }
    }


    const checkAuthToken = async () => {
        const token = localStorage.getItem('token');
        if( !token) return dispatch( onLogout() );

        try {
            const { data} = await loginApi.get( '/auth/renew');
            console.log(data)
            localStorage.setItem('token', data.token);
            localStorage.setItem('token-init-date', new Date().getTime());
            dispatch(onLogin({ name: data.name, uid: data.uid }));
        } catch (error) {
            localStorage.clear();
            dispatch( onLogout( error.response.data?.msg || '--' ));
        }
    }

const startLogout = () => {
    localStorage.clear();
    dispatch( onLogout());
    // dispatch( onLogoutCalendar());
}

    return {
        //* propiedades
        status, user, errorMessage,

        //* metodos
        startLogin, startRegister, checkAuthToken, startLogout
    }
}