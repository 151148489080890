import React from 'react'
import { Navbar } from '../components/Navbar'

export const Entries = () => {
    return (
        <>
            <Navbar />
            <hr />
            <h3>Entries</h3>
        </>
    )
}
