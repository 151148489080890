import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom"
import { LoginPage } from "../login/pages/LoginPage";
import { useAuthStore } from "../login/hooks/useAuthStore";
import { Entries } from "../pages/Entries";
import { getEnvVariables } from "../login/helpers/getEnvVariables";
// import { CalendarPage } from "../calendar";


const { REACT_APP_ALLOW_REGISTER } = getEnvVariables()

// console.log(REACT_APP_ALLOW_REGISTER.toLowerCase() === 'true')
const allowRegister = REACT_APP_ALLOW_REGISTER.toLowerCase() === 'true';
// console.log(allowRegister);

export const AppRouter = () => {

    // const authStatus = 'not-authenticated'; // 'authenticated' // 'not-authenticated'

    const { status, checkAuthToken } = useAuthStore();

    useEffect(() => {
        checkAuthToken();

    }, [])

    if (status === 'checking') {
        return (<h3>Cargando...</h3>);
    }



    return (
        <Routes>
            {
                (status === 'not-authenticated')
                    ? (
                        <>
                            <Route path="/auth/*" element={<LoginPage allowRegister={allowRegister} />} />
                            <Route path="/*" element={<Navigate to="/auth/login" />} />
                        </>
                    )
                    : (
                        <>

                            <Route path="/" element={<Entries />} />
                            <Route path="/*" element={<Navigate to="/" />} />
                        </>
                    )
            }

        </Routes>
    )
}
