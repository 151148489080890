import axios from 'axios'

import {getEnvVariables} from '../helpers/getEnvVariables'

const { REACT_APP_URL_API} = getEnvVariables()

const loginApi = axios.create({
    baseURL: REACT_APP_URL_API,
})

// Todo: cnfigurar interceptores
loginApi.interceptors.request.use( config => {
    config.headers = {
        ...config.headers,
        'x-token': localStorage.getItem('token')
    }

    return config;
})

export default loginApi;
